﻿'use strict'

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeAlerts
    * @description Initializes the JS functionality for handling the alert boxes
    **/
    var initializeAlerts = function () {

        // Close alerts
        const alertSections = document.querySelectorAll('.alert-section');

        alertSections.forEach(section => {
            const closeButton = section.querySelector('.btn-close');

            if (closeButton) {
                closeButton.addEventListener('click', () => {
                    // Remove the alert section from the page
                    section.remove();

                    // Perform an AJAX request to the server to update the session
                    $.ajax({
                        url: '/umbraco/api/AlertsApi/HideAlerts',
                        method: 'POST',
                        success: function () {
                            console.log('Server session updated to hide alert.');
                        },
                        error: function () {
                            console.log('Error updating the alert visibility state on the server.');
                        }
                    });
                });
            }
        });

    };

    // Return an object exposing the public items
    return {
        initialize: initializeAlerts
    };

}());